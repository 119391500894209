import { Collapse, Image, Layout, Row, Skeleton, Space } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { Link } from "react-router-dom";

const CollapsableItems = () => {
  return (
    <Layout>
      <Space size="middle" direction="vertical">
        <Link to={`contact/assistance`}>
          <Collapse
            expandIconPosition="end"
            style={{
              fontFamily: "MyFont",
              borderRadius: 12,
              boxShadow: "3px 5px 12px 3px rgba(208, 216, 243, 0.6)",
            }}
            size="large"
            bordered={false}
            items={[
              {
                key: "1",
                label: (
                  <Row align="middle">
                    <Image
                      preview={false}
                      style={{ marginBottom: 60 }}
                      height={24}
                      width={24}
                      src="https://firebasestorage.googleapis.com/v0/b/m-y-first-project-for-ads.appspot.com/o/support.png?alt=media&token=f4eedcfa-df7f-4d61-b218-b3f4ea30b960"
                    />
                    <div style={{ width: 24 }} />
                    <Title
                      style={{
                        paddingTop: 6,
                        fontFamily: "MyFont",
                      }}
                      level={5}
                    >
                      الدعم والمساعدة
                    </Title>
                  </Row>
                ),
              },
            ]}
          />
        </Link>
        <Link to={`contact/usage-policy`}>
          <Collapse
            expandIconPosition="end"
            style={{
              fontFamily: "MyFont",
              borderRadius: 12,
              boxShadow: "3px 5px 12px 3px rgba(208, 216, 243, 0.6)",
              whiteSpace: "pre",
            }}
            size="large"
            bordered={false}
            items={[
              {
                key: "1",
                label: (
                  <Row align="middle">
                    <Image
                      preview={false}
                      style={{ marginBottom: 60 }}
                      height={24}
                      width={24}
                      src="https://firebasestorage.googleapis.com/v0/b/m-y-first-project-for-ads.appspot.com/o/insurance-policy.png?alt=media&token=a4df2203-4ffd-45b3-a75f-817d4e8ed346"
                    />
                    <div style={{ width: 24 }} />
                    <Title
                      style={{
                        paddingTop: 6,
                        fontFamily: "MyFont",
                      }}
                      level={5}
                    >
                      تعليمات الاستخدام
                    </Title>
                  </Row>
                ),
              },
            ]}
          />
        </Link>

        <Link to={`contact/privacy-policy`}>
          <Collapse
            expandIconPosition="end"
            style={{
              fontFamily: "MyFont",
              borderRadius: 12,
              boxShadow: "3px 5px 12px 3px rgba(208, 216, 243, 0.6)",
              whiteSpace: "pre",
            }}
            size="large"
            bordered={false}
            items={[
              {
                key: "1",
                label: (
                  <Row align="middle">
                    <Image
                      preview={false}
                      style={{ marginBottom: 60 }}
                      height={24}
                      width={24}
                      src="https://firebasestorage.googleapis.com/v0/b/m-y-first-project-for-ads.appspot.com/o/privacy-policy.png?alt=media&token=810bf554-d1b7-4c21-91b8-34cb72eacf33"
                    />
                    <div style={{ width: 24 }} />
                    <Title
                      style={{
                        paddingTop: 6,
                        fontFamily: "MyFont",
                      }}
                      level={5}
                    >
                      سياسة الخصوصية
                    </Title>
                  </Row>
                ),
              },
            ]}
          />
        </Link>

        <Link to={`contact/rules`}>
          <Collapse
            expandIconPosition="end"
            style={{
              fontFamily: "MyFont",
              borderRadius: 12,
              boxShadow: "3px 5px 12px 3px rgba(208, 216, 243, 0.6)",
              whiteSpace: "pre",
            }}
            size="large"
            bordered={false}
            items={[
              {
                key: "1",
                label: (
                  <Row align="middle">
                    <Image
                      preview={false}
                      style={{ marginBottom: 60 }}
                      height={24}
                      width={24}
                      src="https://firebasestorage.googleapis.com/v0/b/m-y-first-project-for-ads.appspot.com/o/rules.png?alt=media&token=2682557a-3dd5-4257-9bc0-d30a427bb012"
                    />
                    <div style={{ width: 24 }} />
                    <Title
                      style={{
                        paddingTop: 6,
                        fontFamily: "MyFont",
                      }}
                      level={5}
                    >
                      الشروط وسياسة الاستخدام
                    </Title>
                  </Row>
                ),
              },
            ]}
          />
        </Link>
      </Space>
    </Layout>
  );
};

export default CollapsableItems;
