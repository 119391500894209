import React, { useEffect, useState } from "react";
import CollapsableItems from "../components/CollapsableItems";
import { Col, Image, Layout, Row, Skeleton, Space } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";
import { useContactIntro } from "../hooks/usePolicy";

const Contact = () => {
  const { data, isLoading } = useContactIntro();
  const [padding, setPadding] = useState("20px");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setPadding("300px");
      } else {
        setPadding("20px");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout>
      <Header
        style={{
          backgroundColor: "#ffffff",
          paddingLeft: 30,
          paddingRight: 30,
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          alignItems: "center",
          boxShadow: "1px 1px 2px 1px rgba(208, 216, 243, 0.6)",
        }}
      >
        <Row align="middle">
          <Col flex="1">
            <Link to={`/`}>
              <Title
                style={{
                  color: "#ffffff",
                  padding: 14,
                  fontFamily: "MyFont",
                }}
                level={3}
              >
                .
              </Title>
            </Link>
          </Col>

          <Col flex="1">
            <Row align="middle">
              <Col flex="1" />
              <Image
                style={{ marginBottom: 60 }}
                height={40}
                preview={false}
                src="https://firebasestorage.googleapis.com/v0/b/m-y-first-project-for-ads.appspot.com/o/watermark%20(1).png?alt=media&token=0bb63f6d-da5c-4587-b594-b416003557fd"
              />
              <Col flex="1" />
            </Row>
          </Col>
          <Col flex="1"></Col>
          {/**
               <Title
                level={3}
                style={{
                  padding: 14,
                  fontFamily: "MyFont",
                }}
              >
                نوركوم
              </Title>
               */}
        </Row>
      </Header>
      <Content>
        <Space
          direction="vertical"
          size="small"
          style={{
            paddingRight: padding,
            paddingLeft: padding,
            paddingTop: 20,
            paddingBottom: 20,
            width: "100%",
          }}
        >
          {/* intro */}
          <Skeleton loading={isLoading} />
          <Skeleton loading={isLoading} />
          {data && (
            <div
              style={{
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {data}
            </div>
          )}

          <div style={{ height: 10 }} />

          <div style={{ backgroundColor: "#caccd1", height: 1 }} />
          <div style={{ height: 5 }} />
          <Row align="middle">
            <Col flex={1} />
            <Col>
              <Row align="middle">
                <Col flex={1} />
                <Col>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.eljousour.noorcom"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      preview={false}
                      style={{ marginBottom: 60 }}
                      height={60}
                      width={205}
                      src="https://www.enervent.com/wp-content/uploads/2018/11/google-play-badge-logo-png-transparent.png"
                    />{" "}
                  </a>
                </Col>
                <Col flex={1} />
              </Row>
              <Row align="middle">
                <Col flex={1} />
                <Col>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.eljousour.noorcom"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      style={{
                        color: "#000",
                        fontFamily: "MyFont",
                        fontWeight: "bold",
                        fontSize: 22,
                      }}
                    >
                      للأندرويد
                    </div>
                  </a>
                </Col>
                <Col flex={1} />
              </Row>
            </Col>
            <Col flex={1} />
          </Row>

          <div style={{ backgroundColor: "#caccd1", height: 1 }} />
          <div style={{ height: 5 }} />
          <Row align="middle">
            <Col flex={1} />
            <Col>
              <Row align="middle">
                <Col flex={1} />
                <Col>
                  <Col>
                    <a
                      href="https://apps.apple.com/dz/app/noorcom-%D9%86%D9%88%D8%B1%D9%83%D9%88%D9%85/id6447770435"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        preview={false}
                        style={{
                          marginBottom: 60,
                        }}
                        height={60}
                        width={196}
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQV0gw526YrdkXrz6UoBC5bbPilAUcA9tJdmQ&usqp=CAU"
                      />
                    </a>
                  </Col>
                </Col>
                <Col flex={1} />
              </Row>
              <Row align="middle">
                <Col flex={1} />
                <Col>
                  <Col>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.eljousour.noorcom"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div
                        style={{
                          color: "#000",
                          fontFamily: "MyFont",
                          fontWeight: "bold",
                          fontSize: 22,
                        }}
                      >
                        للأيفـــون
                      </div>
                    </a>
                  </Col>
                </Col>
                <Col flex={1} />
              </Row>
            </Col>
            <Col flex={1} />
          </Row>

          <div style={{ backgroundColor: "#caccd1", height: 1 }} />
          <div style={{ height: 10 }} />

          <CollapsableItems />
        </Space>
      </Content>

      <Footer
        style={{
          textAlign: "center",

          borderTop: "1px solid #e8e8e8",
        }}
      >
        Noorcom ©2024 All rights reserved
      </Footer>
    </Layout>
  );
};

export default Contact;
