import { initializeApp } from "firebase/app";
import {
  query,
  getFirestore,
  collection,
  doc,
  setDoc,
  orderBy,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import Paragraph from "../components/AdjustedParagraph";
//import flatten from "lodash.flatten";

const firebaseConfig = {
  apiKey: "AIzaSyB046mULcBPiUpcUnyORTdJaNHQas2trjk",
  authDomain: "m-y-first-project-for-ads.firebaseapp.com",
  projectId: "m-y-first-project-for-ads",
  storageBucket: "m-y-first-project-for-ads.appspot.com",
  messagingSenderId: "875393912855",
  appId: "1:875393912855:web:4284a4bd7aca24ee910c52",
  measurementId: "G-JLLC830R23",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export function useContactIntro() {
  return useQuery(["intro"], async ({ queryKey }) => {
    const policyQuery = query(doc(db, "rules/general"));
    const querySnapshot = await getDoc(policyQuery);
    return querySnapshot.data().contactIntro;
  });
}

export function usePolicy(timeFrame) {
  return useQuery(["policy"], async ({ queryKey }) => {
    const [, time] = queryKey;

    const policyQuery = query(
      collection(db, "rules/policy/items"),
      orderBy("timestamp", "asc")
    );
    const querySnapshot = await getDocs(policyQuery);
    const policyItems = [];
    for (let i = 0; i < querySnapshot.size; i++) {
      policyItems.push(
        Object.assign(querySnapshot.docs[i].data(), {
          key: querySnapshot.docs[i].data().timestamp,
          label: (
            <div
              style={{
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {querySnapshot.docs[i].data().title}
            </div>
          ),
          children: Paragraph(querySnapshot.docs[i].data().content),
        })
      );
    }
    return policyItems;
  });
}

// privacy policy
export function usePrivacyPolicy(timeFrame) {
  return useQuery(["privacy"], async ({ queryKey }) => {
    const [, time] = queryKey;

    const adsQuery = query(
      collection(db, "rules/privacy/items"),
      orderBy("timestamp", "asc")
    );
    const querySnapshot = await getDocs(adsQuery);
    const keywords = [];
    for (let i = 0; i < querySnapshot.size; i++) {
      keywords.push(
        Object.assign(querySnapshot.docs[i].data(), {
          key: querySnapshot.docs[i].data().timestamp,
          label: (
            <div
              style={{
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {querySnapshot.docs[i].data().title}
            </div>
          ),
          children: Paragraph(querySnapshot.docs[i].data().content),
        })
      );
    }
    return keywords;
  });
}

// usage
export function useUsage() {
  return useQuery(["usage"], async ({ queryKey }) => {
    const adsQuery = query(
      collection(db, "rules/usage/items"),
      orderBy("timestamp", "asc")
    );
    const querySnapshot = await getDocs(adsQuery);
    const keywords = [];
    for (let i = 0; i < querySnapshot.size; i++) {
      keywords.push(
        Object.assign(querySnapshot.docs[i].data(), {
          key: querySnapshot.docs[i].data().timestamp,
          label: (
            <div
              style={{
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {querySnapshot.docs[i].data().title}
            </div>
          ),
          children: Paragraph(querySnapshot.docs[i].data().content),
        })
      );
    }
    return keywords;
  });
}
export const useUpdateUsageItem = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUsage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["usage"]);
    },
  });
};
const updateUsage = async (updates) => {
  console.log(updates);
  const ref = doc(db, "rules/usage/items/" + updates.timestamp);
  await updateDoc(ref, updates);
  return openNotificationWithIcon("تم التعديل بنجاح");
};
export const useAddUsageItem = () => {
  const queryClient = useQueryClient();

  return useMutation(addUsageItem, {
    onMutate: (selectedItem) => {
      // add   new ad to cache
      queryClient.setQueriesData(["usage"], (oldKeywords) => {
        return [selectedItem].concat(oldKeywords);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["usage"]);
    },
  });
};
const addUsageItem = async (item) => {
  const ref = doc(db, "rules/usage/items/" + item.timestamp);
  await setDoc(ref, item);
  return openNotificationWithIcon("تم اضافة العنصر بنجاح");
};
////
export function useGenralRuls() {
  return useQuery(["levels"], async ({ queryKey }) => {
    const docRef = doc(db, "rules/general");
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  });
}

export const useSendContact = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(sendContact, {
    onMutate: (selectedItem) => {
      // add   new ad to cache
      queryClient.setQueriesData(["contact"], (oldKeywords) => {
        return [selectedItem].concat(oldKeywords);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["contact"]);
      navigate("/");
    },
  });
};

const sendContact = async (item) => {
  const timestamp = Date.now().toString();
  const ref = doc(db, "contacts/" + timestamp);
  await setDoc(
    ref,
    Object.assign(item, {
      userName: "مستخدم غير مسجل",
      userId: "unknown",
      timestamp: timestamp,
      status: "معلق",
      from: "الويب",
    })
  );
  return openNotificationWithIcon("تم استقبال رسالتك بنجاح");
};
export const useSendDeleteRequest = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(sendDeleteRequest, {
    onMutate: (selectedItem) => {
      // add   new ad to cache
      queryClient.setQueriesData(["delete"], (oldKeywords) => {
        return [selectedItem].concat(oldKeywords);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["delete"]);
      navigate("/");
    },
  });
};

const sendDeleteRequest = async (item) => {
  const timestamp = Date.now().toString();
  const ref = doc(db, "deleteRequests/" + timestamp);
  await setDoc(
    ref,
    Object.assign(item, {
      userId: "unknown",
      timestamp: timestamp,
      status: "معلق",
      from: "الويب",
      userImage: "",
    })
  );
  return openNotificationWithIcon("تم إرسال طلب حذف حسابك بنجاح");
};
const openNotificationWithIcon = (message) => {
  notification["success"]({
    message: message,
  });
};
