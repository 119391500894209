import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";
import { useSendDeleteRequest } from "../hooks/usePolicy";

const DeleteAccount = () => {
  const [padding, setPadding] = useState("20px");
  const [rulesConfirmed, setRulesConfirmed] = useState(false);
  const [form] = Form.useForm();
  const formRef = React.useRef(null);
  const { mutate: sendRequest } = useSendDeleteRequest();
  const [isLoading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    sendRequest(values);
    //form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const deleteReasons = [
    "لا أستخدم التطبيق بانتظام.",
    "وجدت تطبيقًا آخر يلبي احتياجاتي بشكل أفضل.",
    "لدي مخاوف بشأن الخصوصية والأمان.",
    "تلقيت الكثير من الإشعارات غير المرغوب فيها.",
    "واجهت مشاكل تقنية متكررة.",
    "لم أكن راضيًا عن خدمة العملاء.",
    "لا أحتاج إلى التطبيق بعد الآن.",
    "هناك مشاكل في واجهة المستخدم وتجربة المستخدم.",
    "تلقيت محتوى غير ملائم أو غير مرغوب فيه.",
    "أواجه مشاكل في الدفع أو الفواتير.",
    "لا أجد محتوى التطبيق مفيدًا أو ذا صلة.",
    "لدي حسابات مكررة وأرغب في الاحتفاظ بواحد فقط.",
    "لم أعد أستخدم الخدمة لأسباب شخصية.",
    "أرغب في الحد من استخدامي للتطبيقات.",
    "لدي مخاوف بشأن كيفية استخدام بياناتي الشخصية.",
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setPadding("300px");
      } else {
        setPadding("20px");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout>
      <Header
        style={{
          backgroundColor: "#ffffff",
          paddingLeft: 30,
          paddingRight: 30,
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          alignItems: "center",
          boxShadow: "1px 1px 2px 1px rgba(208, 216, 243, 0.6)",
        }}
      >
        <Row align="middle">
          <Col flex="1">
            <Link to={`/`}>
              <Title
                style={{
                  color: "#007fdb",
                  paddingTop: 18,
                  fontFamily: "MyFont",
                }}
                level={4}
              >
                الرئيسية
              </Title>
            </Link>
          </Col>

          <Col flex="1">
            <Row align="middle">
              <Col flex="1" />
              <Image
                style={{ marginBottom: 20 }}
                height={40}
                preview={false}
                src="https://firebasestorage.googleapis.com/v0/b/m-y-first-project-for-ads.appspot.com/o/watermark%20(1).png?alt=media&token=0bb63f6d-da5c-4587-b594-b416003557fd"
              />
              <Col flex="1" />
            </Row>
          </Col>
          <Col flex="1"></Col>
        </Row>
      </Header>
      <Content
        style={{
          paddingRight: padding,
          paddingLeft: padding,
          paddingTop: 20,
          paddingBottom: 20,
          width: "100%",
        }}
      >
        <Title
          style={{
            paddingTop: 18,
            fontFamily: "MyFont",
          }}
          level={4}
        >
          حذف الحساب على نوركوم
        </Title>
        {!rulesConfirmed && (
          <Space
            direction="vertical"
            size="small"
            style={{
              width: "100%",
              paddingTop: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: 12,
                borderRadius: 12,
                color: "#BF483F",
              }}
            >
              أنت على وشك تقديم طلب تقديم طلب لحذف حسابك وبياناتك بشكل نهائي،
              سيتم حذف كل محتويات حسابك بما فيها إعلاناتك وتقييماتك ومحادثاتك
              وتعليقاتك وتفاعل المستخدمين مع محتوى حسابك
            </div>
            <Title
              style={{
                paddingTop: 18,
                fontFamily: "MyFont",
              }}
              level={4}
            >
              يرجى مراعاة مايلي
            </Title>
            <Space align="start">
              <b>●</b>
              <div>
                يعد حذف حسابك على نوركوم إجراء نهائي، ولايمكن التراجع عنه أو
                استعادته
              </div>
            </Space>
            <Space align="start">
              <b>●</b>
              <div>
                تستغرق عملية حذف حسابك 7 أيام من وقت إرسال الطلب، وقج تمتد هذه
                الفترة إلى 30 يوما في بعض الحالات
              </div>
            </Space>
            <Space align="start">
              <b>●</b>
              <div>
                بعد حذف حسابك لن تتمكن من إنشاء حساب آخر بنفس رقم الجوال قبل
                مرور 90 يوم
              </div>
            </Space>
            <Button
              danger
              type="primary"
              onClick={() => setRulesConfirmed(true)}
              style={{
                borderRadius: 8,
                marginTop: 60,
                marginBottom: 150,
                width: "100%",
              }}
            >
              أتفهم ذلك، وأستمر
            </Button>
          </Space>
        )}
        {rulesConfirmed && (
          <Form
            layout="vertical"
            form={form}
            ref={formRef}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="false"
            style={{ paddingTop: 20 }}
          >
            <Form.Item
              name="reason"
              label="سبب الحذف"
              rules={[{ required: true, message: " اختر سبب الحذف" }]}
            >
              <Select
                size="large"
                loading={isLoading}
                disabled={isLoading}
                bordered={false}
                defaultValue="اختر سبب الحذف"
                style={{
                  fontFamily: "MyFont",
                  borderRadius: 8,
                  backgroundColor: "#fff",
                  border: "1px",
                  borderColor: "#caccd1",
                  borderStyle: "solid",
                  width: "100%",
                  fontSize: 18,
                }}
                dropdownStyle={{
                  fontSize: 18,
                }}
              >
                {deleteReasons.map((reasonItem) => (
                  <Select.Option
                    key={reasonItem}
                    value={reasonItem}
                    style={{
                      fontFamily: "MyFont",
                      fontSize: 16,
                    }}
                  >
                    {reasonItem}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="username"
              label="اسمك"
              rules={[{ required: true, message: " يجب إدراج إسمك " }]}
            >
              <Input
                size="large"
                disabled={isLoading}
                loading={isLoading}
                maxLength={40}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  fontFamily: "MyFont",
                  fontSize: 17,
                  marginBottom: 0,
                }}
                placeholder="اسمك كما هو في حسابك"
              />
            </Form.Item>
            <Form.Item
              name="userMainPhoneNumber"
              label="رقم الجوال"
              rules={[{ required: true, message: " يجب إدراج رقم الجوال " }]}
            >
              <Input
                size="large"
                disabled={isLoading}
                loading={isLoading}
                maxLength={40}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  fontFamily: "MyFont",
                  fontSize: 17,
                  marginBottom: 0,
                }}
                placeholder="رقم جوالك في حسابك"
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="كلمة المرور"
              rules={[
                { required: true, message: " يجب إدراج رقم كلمة المرور " },
              ]}
            >
              <Input.Password
                size="large"
                disabled={isLoading}
                loading={isLoading}
                maxLength={40}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  fontFamily: "MyFont",
                  fontSize: 17,
                  marginBottom: 0,
                }}
                placeholder="كلمة مرور حسابك"
              />
            </Form.Item>
            <div style={{ height: 10 }} />
            <Form.Item>
              <Button
                danger
                type="primary"
                size="large"
                htmlType="submit"
                style={{
                  borderRadius: 8,
                  fontFamily: "MyFont",
                  width: "100%",
                  fontSize: 18,
                }}
                loading={isLoading}
              >
                حذف الحساب الآن
              </Button>
            </Form.Item>
          </Form>
        )}
      </Content>
      <Footer
        style={{
          textAlign: "center",

          borderTop: "1px solid #e8e8e8",
        }}
      >
        Noorcom ©2024 All rights reserved
      </Footer>
    </Layout>
  );
};

export default DeleteAccount;
