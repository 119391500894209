import React from "react";
import { ConfigProvider } from "antd";
import "./App.css";
import "antd/dist/antd.variable.min.css";
import locale from "antd/lib/locale/ar_EG";
import { initializeApp } from "firebase/app";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Contact from "./pages/Contact";
import Assistance from "./pages/Assistance";
import UsagePolicy from "./pages/UsagePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Rules from "./pages/Rules";
import DeleteAccount from "./pages/DeleteAccount";

const firebaseConfig = {
  apiKey: "AIzaSyB046mULcBPiUpcUnyORTdJaNHQas2trjk",
  authDomain: "m-y-first-project-for-ads.firebaseapp.com",
  projectId: "m-y-first-project-for-ads",
  storageBucket: "m-y-first-project-for-ads.appspot.com",
  messagingSenderId: "875393912855",
  appId: "1:875393912855:web:4284a4bd7aca24ee910c52",
  measurementId: "G-JLLC830R23",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const queryClient = new QueryClient();
const router = createBrowserRouter([
  // main
  {
    path: "/",
    element: <Contact />,
    errorElement: <div>Error</div>,
  },
  {
    path: "/users/:id",
    element: <Contact />,
    errorElement: <div>Error</div>,
  },
  {
    path: "/ads/:id",
    element: <Contact />,
    errorElement: <div>Error</div>,
  },

  // redirects
  {
    path: "/ads/:id/contact/assistance",
    element: <Navigate to="/contact/assistance" replace />,
  },
  {
    path: "/users/:id/contact/assistance",
    element: <Navigate to="/contact/assistance" replace />,
  },

  {
    path: "/ads/:id/contact/usage-policy",
    element: <Navigate to="/contact/usage-policy" replace />,
  },
  {
    path: "/users/:id/contact/usage-policy",
    element: <Navigate to="/contact/usage-policy" replace />,
  },

  {
    path: "/ads/:id/contact/privacy-policy",
    element: <Navigate to="/contact/privacy-policy" replace />,
  },
  {
    path: "/users/:id/contact/privacy-policy",
    element: <Navigate to="/contact/privacy-policy" replace />,
  },

  {
    path: "/ads/:id/contact/rules",
    element: <Navigate to="/contact/rules" replace />,
  },
  {
    path: "/users/:id/contact/rules",
    element: <Navigate to="/contact/rules" replace />,
  },

  // main routes

  {
    path: "contact/assistance",
    element: <Assistance />,
  },
  {
    path: "contact/usage-policy",
    element: <UsagePolicy />,
  },
  {
    path: "contact/delete-account",
    element: <DeleteAccount />,
  },
  {
    path: "contact/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "contact/rules",
    element: <Rules />,
  },
]);
ConfigProvider.config({
  theme: {
    primaryColor: "#009bbb",
  },
});
const App = () => {
  return (
    <ConfigProvider
      direction="rtl"
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#009bbb",
          fontFamily: "MyFont",
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <div>
          <RouterProvider router={router} />
        </div>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
