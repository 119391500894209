import React from "react";

const AdjustedParagraph = (text) => {
  return (
    <div>
      {text.split("\n\n").map((paragraph) => (
        <p>
          {paragraph.split("\n").reduce((total, line) => [total, <br />, line])}
        </p>
      ))}
    </div>
  );
};

export default AdjustedParagraph;
