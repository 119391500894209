import React, { useEffect, useState } from "react";
import {
  Col,
  Collapse,
  Divider,
  Image,
  Layout,
  Row,
  Skeleton,
  Space,
} from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";
import { usePrivacyPolicy } from "../hooks/usePolicy";

const PrivacyPolicy = () => {
  const { data: data, isLoading: isLoading } = usePrivacyPolicy();
  const [padding, setPadding] = useState("20px");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setPadding("300px");
      } else {
        setPadding("20px");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header
        style={{
          backgroundColor: "#ffffff",
          paddingLeft: 30,
          paddingRight: 30,
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          alignItems: "center",
          boxShadow: "1px 1px 2px 1px rgba(208, 216, 243, 0.6)",
        }}
      >
        <Row align="middle">
          <Col flex="1">
            <Link to={`/`}>
              <Title
                style={{
                  color: "#007fdb",
                  paddingTop: 18,
                  fontFamily: "MyFont",
                }}
                level={4}
              >
                الرئيسية
              </Title>
            </Link>
          </Col>

          <Col flex="1">
            <Row align="middle">
              <Col flex="1" />
              <Image
                style={{ marginBottom: 20 }}
                height={40}
                preview={false}
                src="https://firebasestorage.googleapis.com/v0/b/m-y-first-project-for-ads.appspot.com/o/watermark%20(1).png?alt=media&token=0bb63f6d-da5c-4587-b594-b416003557fd"
              />
              <Col flex="1" />
            </Row>
          </Col>
          <Col flex="1"></Col>

          {/**
               <Title
                level={3}
                style={{
                  padding: 14,
                  fontFamily: "MyFont",
                }}
              >
                نوركوم
              </Title>
               */}
        </Row>
      </Header>
      <Content
        style={{
          paddingRight: padding,
          paddingLeft: padding,
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Row align="middle">
          <Col flex={1}>
            <Divider />
          </Col>
          <div style={{ width: 20 }} />
          <Col>
            <Title
              style={{
                paddingTop: 18,
                fontFamily: "MyFont",
              }}
              level={4}
            >
              سياسة الخصوصية
            </Title>
          </Col>
          <div style={{ width: 20 }} />
          <Col flex={1}>
            <Divider />
          </Col>{" "}
        </Row>
        <Space
          direction="vertical"
          size="small"
          style={{ paddingTop: 20, width: "100%" }}
        >
          <Skeleton loading={isLoading} />
          {data &&
            data.map((item) => (
              <Collapse
                expandIconPosition="end"
                style={{
                  borderColor: "#f3f4f7",
                  borderRadius: 8,
                  boxShadow: "1px 2px 3px 1px rgba(208, 216, 243, 0.6)",
                }}
                size="middle"
                bordered={true}
                items={[item]}
              />
            ))}
        </Space>
      </Content>

      <Footer
        style={{
          textAlign: "center",

          borderTop: "1px solid #e8e8e8",
        }}
      >
        Noorcom ©2024 All rights reserved
      </Footer>
    </Layout>
  );
};

export default PrivacyPolicy;
