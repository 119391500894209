import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useSendContact } from "../hooks/usePolicy";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import Title from "antd/es/typography/Title";

const Assistance = () => {
  const [reason, setReason] = useState("اختر سبب التواصل");
  const [details, setDetails] = useState("");
  const [contactUtil, setContactUtil] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = React.useRef(null);
  const { mutate: sendContact } = useSendContact();
  const onFinish = (values) => {
    setLoading(true);
    sendContact(values);
    //form.resetFields();
    console.log(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const contactReasons = [
    "مشكلة في حسابي",
    "مشكلة في التطبيق",
    "إقتراح",
    "إستفسار",
    "شكوى",
    "غير ذلك",
  ];
  const [padding, setPadding] = useState("20px");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setPadding("300px");
      } else {
        setPadding("20px");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header
        style={{
          backgroundColor: "#ffffff",
          paddingLeft: 30,
          paddingRight: 30,
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          alignItems: "center",
          boxShadow: "1px 1px 2px 1px rgba(208, 216, 243, 0.6)",
        }}
      >
        <Row align="middle">
          <Col flex="1">
            <Link to={`/`}>
              <Title
                style={{
                  color: "#007fdb",
                  paddingTop: 18,
                  fontFamily: "MyFont",
                }}
                level={4}
              >
                الرئيسية
              </Title>
            </Link>
          </Col>

          <Col flex="1">
            <Row align="middle">
              <Col flex="1" />
              <Image
                style={{ marginBottom: 20 }}
                height={40}
                preview={false}
                src="https://firebasestorage.googleapis.com/v0/b/m-y-first-project-for-ads.appspot.com/o/watermark%20(1).png?alt=media&token=0bb63f6d-da5c-4587-b594-b416003557fd"
              />
              <Col flex="1" />
            </Row>
          </Col>
          <Col flex="1"></Col>

          {/**
               <Title
                level={3}
                style={{
                  padding: 14,
                  fontFamily: "MyFont",
                }}
              >
                نوركوم
              </Title>
               */}
        </Row>
      </Header>
      <div
        style={{
          backgroundColor: "#caccd1",
          height: 1,
        }}
      />
      <Content
        style={{
          paddingRight: padding,
          paddingLeft: padding,
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Row align="middle">
          <Col flex={1}>
            <Divider />
          </Col>
          <div style={{ width: 20 }} />
          <Col>
            <Title
              style={{
                paddingTop: 18,
                fontFamily: "MyFont",
              }}
              level={4}
            >
              الدعم والمساعدة
            </Title>
          </Col>
          <div style={{ width: 20 }} />
          <Col flex={1}>
            <Divider />
          </Col>{" "}
        </Row>{" "}
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="false"
          style={{ paddingTop: 20 }}
        >
          <Form.Item
            name="reason"
            label="سبب التواصل"
            rules={[{ required: true, message: " اختر سبب التواصل" }]}
          >
            <Select
              size="large"
              loading={isLoading}
              disabled={isLoading}
              bordered={false}
              defaultValue="اختر سبب التواصل"
              style={{
                fontFamily: "MyFont",
                borderRadius: 8,
                backgroundColor: "#fff",
                border: "1px",
                borderColor: "#caccd1",
                borderStyle: "solid",
                width: "100%",
                fontSize: 18,
              }}
              dropdownStyle={{
                fontSize: 18,
              }}
              onChange={(e) => {
                setReason(e);
              }}
            >
              {contactReasons.map((reasonItem) => (
                <Select.Option
                  key={reasonItem}
                  value={reasonItem}
                  style={{
                    fontFamily: "MyFont",
                    fontSize: 16,
                  }}
                >
                  {reasonItem}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/** details */}
          <Form.Item
            name="detail"
            label="التفاصيل"
            rules={[{ required: true }]}
          >
            <TextArea
              loading={isLoading}
              disabled={isLoading}
              maxLength={500}
              size="large"
              rows={8}
              style={{
                backgroundColor: "#fff",
                borderRadius: 8,
                fontSize: 17,
              }}
              placeholder="اكتب هنا التفاصيل"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </Form.Item>
          <div style={{ textAlign: "left", fontSize: 15, color: "GrayText" }}>
            {details.length} / 500
          </div>

          <Form.Item
            name="contact"
            label="وسيلة التواصل"
            rules={[{ required: true, message: " يجب ادراج وسيلة التواصل" }]}
          >
            <Input
              size="large"
              disabled={isLoading}
              loading={isLoading}
              maxLength={40}
              style={{
                backgroundColor: "#fff",
                borderRadius: 8,
                fontFamily: "MyFont",
                fontSize: 17,
                marginBottom: 0,
              }}
              placeholder="الايميل (example@example.com)"
              value={contactUtil}
              onChange={(e) => setContactUtil(e.target.value)}
            />
          </Form.Item>
          <div style={{ height: 10 }} />
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{
                borderRadius: 8,
                fontFamily: "MyFont",
                width: "100%",
                fontSize: 18,
              }}
              loading={isLoading}
            >
              إرسال
            </Button>
          </Form.Item>
        </Form>
      </Content>

      <Footer
        style={{
          textAlign: "center",

          borderTop: "1px solid #e8e8e8",
        }}
      >
        Noorcom ©2024 All rights reserved
      </Footer>
    </Layout>
  );
};

export default Assistance;
